import { useMutation, useQuery } from '@apollo/client';
import { GETVIDEOMONITORINGALERTS, SETVIDEOMONITORINGALERTSREAD } from '_queries';
import { AlertsQuery } from '_types/queries';
import AlertManager from 'features/DataManager/AlertManager/AlertManager';
import moment from 'moment';
import React from 'react';
import './notificationCenter.scss';
import { capitalize } from 'components/util';
import { useAppSelector } from 'app/hooks';
import { selectAlerts } from 'features/StationView/AlertSlice';

const NotificationCenter: React.FC = () => {
    const alerts = useAppSelector(selectAlerts);
    const [setAlertsRead] = useMutation(SETVIDEOMONITORINGALERTSREAD);

    React.useEffect(() => {
        alerts.forEach((alert) => {
            if (alert.read_status === 1) return;
            setAlertsRead({ variables: { site_id: alert.site_id, time_stamp: alert.time_stamp } });
        });
    }, [alerts]);

    return (
        <div className="notification-center">
            <span>Notification center</span>
            {[...alerts]
                .sort((a, b) => a && b && +b.time_stamp - +a.time_stamp)
                .map((alert) => (
                    <Message alert={alert} key={alert.alarm_id + alert.alarm_status} />
                ))}
        </div>
    );
};

export default NotificationCenter;

export const Message: React.FC<{ alert: AlertsQuery }> = ({ alert }) => {
    const isToday =
        new Date(+alert.time_stamp).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);
    const timeFormat = isToday ? 'HH:mm:ss' : 'ddd MMM Do YYYY, HH:mm:ss';
    const date = moment(+alert.time_stamp);

    const onClick = () => {
        AlertManager.getInstance().SetViewToAlertPanel(alert.site_id);
    };

    return (
        <div onClick={onClick} className="notification">
            <div className="notification-first-section">
                <div className="notification-head">{`${date.format(
                    'dddd MMMM Do YYYY'
                )} - ${capitalize(alert.alarm_status)}`}</div>
                <div className="notification-body">{alert.genai_message}</div>
                <div className="notification-footer">{capitalize(alert.site_id)}</div>
            </div>

            <div className="notification-second-section">
                <div className="notification-timestamp">{date.format(timeFormat)}</div>
            </div>
        </div>
    );
};
