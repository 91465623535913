import { StationParams } from '_types';
import * as THREE from 'three';
import React from 'react';
import { ReactComponent as AlertIconFilled } from 'assets/icons/alert-triangle-filled.svg';
import { renderToStaticMarkup, renderToString } from 'react-dom/server';

export const applyStationParams = (
    obj: THREE.Object3D,
    stationParams: StationParams | undefined
) => {
    if (!stationParams) return;

    if (stationParams.scale) {
        const { x, y, z } = stationParams.scale;
        obj.scale.set(x, y, z);
    }
    if (stationParams.position) {
        const { x, y, z } = stationParams.position;
        obj.position.add(new THREE.Vector3(x, y, z));
    }
    if (stationParams.rotate) {
        const { axis, angle } = stationParams.rotate;
        obj.rotateOnAxis(axis, angle);
    }
};

export const setYAxisVisibility = (yPosition: number, bottom: number, top: number) => {
    return yPosition < top && yPosition > bottom;
};

export const generateAlertPanelContent = (
    textContent: string,
    contentProps?: React.HTMLProps<HTMLDivElement>
) => {
    const content = (
        <div
            {...contentProps}
            style={{
                width: '170px',
                background: '#4c575eb3',
                padding: '1rem',
                ...contentProps?.style,
            }}>
            <div className="title" style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <AlertIconFilled fill="white" style={{}} />
                <div style={{}}>
                    <span>{textContent}</span>
                </div>
            </div>
        </div>
    );

    return renderToString(content);
};
