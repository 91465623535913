import { useAppDispatch, useAppSelector } from 'app/hooks';
import { setMainSidebarFull } from 'features/StationView/StationViewSlice';
import React from 'react';
import './virtualOperatorView.scss';
import { useMutation, useQuery } from '@apollo/client';
import { GETBRIEFINGS, SETVIDEOMONITORINGALERTSREAD } from '_queries';
import { BriefingsQueryType } from '_types/queries';
import { selectAlerts } from 'features/StationView/AlertSlice';
import { alertsColor, AlertsIcon } from 'components/AlertToaster/AlertToaster';
import { Button } from 'components/UIComponents/Button/Button';
import moment from 'moment';
import { ReactComponent as ArrowRight } from 'assets/icons/left-icon.svg';
import { capitalize } from 'components/util';
import AlertManager from 'features/DataManager/AlertManager/AlertManager';

const VirtualOperatorView: React.FC = () => {
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(setMainSidebarFull(false));
    }, []);

    // FETCHING BRIEFINGS AND ASCALATOR DOWN ALERTS //

    const briefingsQuery = useQuery<BriefingsQueryType>(GETBRIEFINGS);
    // const escalatorDownAlertsQuery = useQuery<EscalatorDownAlertsQuery>(GETESCALATORDOWNALERTS);
    React.useEffect(() => {
        briefingsQuery.startPolling(1000 * 60);
        // escalatorDownAlertsQuery.startPolling(500);

        return () => {
            briefingsQuery.stopPolling();
            // escalatorDownAlertsQuery.stopPolling();
        };
    }, []);

    // FETCHING VIDEOMONITORING ALERTS AND SETTING THEM ON READ //

    const videoMonitoringAlerts = useAppSelector(selectAlerts);
    const [setAlertsRead] = useMutation(SETVIDEOMONITORINGALERTSREAD);
    React.useEffect(() => {
        videoMonitoringAlerts.forEach((alert) => {
            if (alert.read_status === 1) return;
            setAlertsRead({ variables: { site_id: alert.site_id, time_stamp: alert.time_stamp } });
        });
    }, [videoMonitoringAlerts]);

    return (
        <div id="virtual-operator-view">
            <div className="title-section">
                <div className="title">{moment().format('ddd, DD MMMM yyyy')}</div>
            </div>
            {videoMonitoringAlerts
                .map((alert) => [
                    moment(+alert.time_stamp),
                    <AlertNotification
                        severity={3}
                        timestampMs={+alert.time_stamp}
                        title={alert.genai_message}
                        key={alert.alarm_id + alert.alarm_status}
                        onInspect={() =>
                            AlertManager.getInstance().SetViewToAlertPanel(
                                alert.site_id,
                                undefined,
                                true
                            )
                        }
                    />,
                ])
                .sort(([m1], [m2]) =>
                    (m1 as moment.Moment).isBefore(m2 as moment.Moment) ? 1 : -1
                )
                .map(([, el]) => el as React.ReactElement)}
        </div>
    );
};

export default VirtualOperatorView;

type ALertNotificationProps = {
    severity: 1 | 2 | 3;
    timestampMs: number;
    title: string;
    onInspect?: () => void;
};
const AlertNotification: React.FC<ALertNotificationProps> = (props) => {
    const { severity, timestampMs, title, onInspect } = props;
    const color = alertsColor(severity);
    return (
        <div className="virt-op-view-alerts" style={{ fill: color }}>
            <AlertsIcon severity={severity} />
            <div className="virt-op-view-alert-body">
                <div className="virt-op-view-alert-body-title">{capitalize(title)}</div>
                <div className="virt-op-view-alert-body-time">
                    {moment(timestampMs).format('ddd, DD MMMM yyyy HH:mm')}
                </div>
                <div className="virt-op-view-alert-body-inspect-button">
                    <Button
                        className="secondary small"
                        icon={<ArrowRight fill="currentColor" />}
                        onClick={() => onInspect && onInspect()}>
                        Inspect
                    </Button>
                </div>
            </div>
        </div>
    );
};

/* type DateElementProps = {
    moment: moment.Moment;
};
const DateElement: React.FC<DateElementProps> = (props) => {
    const { moment } = props;
    return <div className="date-element">{moment.format('ddd, DD MMMM yyyy')}</div>;
}; */
