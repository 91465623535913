import React from 'react';
import { FiFastForward } from 'react-icons/fi';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import './mainSidebar.scss';
import { Link, useLocation } from 'wouter';
import { selectMainSidebarFull, setMainSidebarFull } from 'features/StationView/StationViewSlice';
import { ReactComponent as LeftSideArrow } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as OfficeIcon } from '../../assets/icons/office-building-2.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail-icon.svg';
import { ReactComponent as NotificationBell } from 'assets/icons/notification-bell.svg';
import AlertManager from 'features/DataManager/AlertManager/AlertManager';
import { selectAlertsToRead } from 'features/StationView/AlertSlice';

const MainSidebar: React.FC<{ domain?: string }> = ({ domain }) => {
    const dispatch = useAppDispatch();
    const isFull = useAppSelector(selectMainSidebarFull);
    const alertsToRead = useAppSelector(selectAlertsToRead);

    const [location, setLocation] = useLocation();

    return (
        <div className={'mainSidebar ' + (isFull ? '' : 'reduced')}>
            <nav>
                <ul>
                    <li className={domain === 'overview' ? 'active' : ''}>
                        <div
                            className="main-sidebar-element"
                            onClick={() => setLocation('/overview')}>
                            <span className="icon">
                                <OfficeIcon />
                            </span>
                            {isFull ? <span>Stations</span> : null}
                        </div>
                    </li>
                    <li className={domain === 'simulator' ? 'active' : ''}>
                        <div
                            className="main-sidebar-element"
                            onClick={() => setLocation('/simulations')}>
                            <span className="icon">
                                <FiFastForward />
                            </span>
                            {isFull ? <span>Simulator</span> : null}
                        </div>
                    </li>
                    <li
                        className={
                            (domain === 'notifications' ? 'active ' : '') +
                            (alertsToRead ? 'notification-to-read' : '')
                        }>
                        <div
                            className="main-sidebar-element"
                            onClick={() => setLocation('/notification-center')}>
                            <span className="icon">
                                <NotificationBell fill="white" />
                            </span>
                            {isFull ? <span>Notifications</span> : null}
                        </div>
                    </li>
                    <li>
                        <a href="mailto:stationtwinsupport@kone.com?subject=StationTwin Feedback">
                            <span className="icon">
                                <MailIcon fill="white" />
                            </span>
                            {isFull ? <span>Feedback</span> : null}
                        </a>
                    </li>
                    <li>
                        <div
                            className={'reducer ' + (isFull ? '' : 'reverse')}
                            onClick={() => dispatch(setMainSidebarFull(!isFull))}>
                            <div style={{ marginRight: '-17px' }}>
                                <LeftSideArrow />
                            </div>
                            <div>
                                <LeftSideArrow />
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default MainSidebar;
